import React from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { FormattedMessage } from '../../../../util/reactIntl';
import { LinkedLogo, NamedLink } from '../../../../components';

import IconFacebook from './icons/IconFacebook';
import IconTweeter from './icons/IconTweeter';
import IconLinkedin from './icons/IconLinkedin';
import IconPinterest from './icons/IconPinterest';
import IconInstagram from './icons/IconInstagram';
import IconYoutube from './icons/IconYoutube';


import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';


const MAX_MOBILE_SCREEN_WIDTH = 1024;

const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    appearance,
    options,
    linkLogoToExternalSite,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.footer}>
        <div className={css.content}>
          <div className={css.col1}>
            <div>
              <LinkedLogo
                rootClassName={css.logoLink}
                logoClassName={css.logoWrapper}
                logoImageClassName={css.logoImage}
                linkToExternalSite={linkLogoToExternalSite}
                layout={logoLayout}
              />
              <p className={css.slogan}>
                <FormattedMessage id="SectionFooter.slogan" />
              </p>
            </div>
            <div className={css.icons}>
              <IconFacebook />
              <IconTweeter />
              <IconLinkedin />
              <IconInstagram />
              <IconPinterest />
              <IconYoutube />
            </div>
            <div className={css.emailWrapper}>
              <FormattedMessage id="SectionFooter.subscribeToNewsletter" />
              <div className={css.emailInput}>
                <input
                  type="email"
                  placeholder="Email address"
                />
                <button className={css.submit} type='submit'>Subscribe</button>
              </div>
            </div>
          </div>
          <div>
            <ul>
              <li className={css.listHeader}>Help</li>
              <li>
                <NamedLink name="WhatIsHouseSittingPage">How it works</NamedLink>
              </li>
              <li>
                <NamedLink name="MembershipPlansPage">Pricing</NamedLink>
              </li>
              <li>
                <NamedLink name="TrustAndSafetyPage">Trust and safety</NamedLink>
              </li>
              {/* <li>Help desk</li> */}
              {/* <li>Home and Contents Plan</li> */}
              <li>
                <a href="https://uk.trustpilot.com/review/housesitmatch.com" target="_blank">
                  Reviews and Testimonials
                </a>
              </li>
              <li>
                <a href="https://www.housesitmatch.com/housesitting-visa/" target="_blank">
                  Housesitting Visas
                </a>
              </li>
              <li>Trip Cancellation Support</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className={css.listHeader}>Discover more</li>
              <li>
                <NamedLink name="AboutHouseSitMatchPage">About us</NamedLink>
              </li>
              <li>
                <a href="https://www.facebook.com/groups/1471136503122934/" target="_blank">
                  Housesitting community
                </a>
              </li>
              <li>
                <a href='https://www.housesitmatch.com/blog/house-sitting-and-sustainable-travel/' target='_blank'>
                  Green Travel and Housesitting
                </a>
              </li>
              <li>
                <a href='https://www.housesitmatch.com/blog/category/house-sitting/' target='_blank'>
                  Housesitting Blogs
                </a>
              </li>
              <li>
                <NamedLink name="PressRoomPage">Press room</NamedLink>
              </li>
              <li>Affiliates</li>
            </ul>
          </div>
          <div>
            <ul>
              <li className={css.listHeader}>Start searching for...</li>
              <li>
                <a href='https://www.housesitmatch.com/list-housesitters/' target='_blank'>
                  Pet sitters
                </a>
              </li>
              <li>
                <a href='https://www.housesitmatch.com/blog/dogsitters-near-me-is-this-what-you-need/' target='_blank'>
                  Dog sitters near me
                </a>
              </li>
              <li>
                <a href='https://www.housesitmatch.com/blog/top-tips-cat-sitters-cat-owners/' target='_blank'>
                  Cat sitters and Cat owners
                </a>
              </li>
              <li>
                <a href='https://www.housesitmatch.com/blog/find-a-great-petsitter/' target='_blank'>
                  Find a Pet sitter
                </a>
              </li>
              <li>
                <a href='https://www.housesitmatch.com/blog/sam-pete-need-long-term-house-sitters/' target='_blank'>
                  Find a long term housesit
                </a>
              </li>
              <li>Pet sitters in London</li>
            </ul>
          </div>

        </div>
        <div className={css.bottomWrapper}>
          <p className={css.copyright}>
            <FormattedMessage id="SectionFooter.copyright" />
          </p>
          <div className={css.links}>
            <NamedLink name="TermsOfServicePage">Terms of Use</NamedLink>
            <NamedLink name="TermsAndConditions">Terms and Conditions of Supply</NamedLink>
            <NamedLink name="PrivacyPolicyPage">Privacy Policy / How we use Cookies</NamedLink>
          </div>
        </div>
      </div>
    </SectionContainer >
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
