import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Icon.module.css';

const IconLinkedin = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <a href="https://www.linkedin.com/company/housesit-match-ltd/" target="_blank">
            <svg
                className={classes}
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.9375 0.1875C14.502 0.1875 15 0.685547 15 1.2832V14C15 14.5977 14.502 15.0625 13.9375 15.0625H1.1543C0.589844 15.0625 0.125 14.5977 0.125 14V1.2832C0.125 0.685547 0.589844 0.1875 1.1543 0.1875H13.9375ZM4.60742 12.9375V5.86523H2.41602V12.9375H4.60742ZM3.51172 4.86914C4.20898 4.86914 4.77344 4.30469 4.77344 3.60742C4.77344 2.91016 4.20898 2.3125 3.51172 2.3125C2.78125 2.3125 2.2168 2.91016 2.2168 3.60742C2.2168 4.30469 2.78125 4.86914 3.51172 4.86914ZM12.875 12.9375V9.05273C12.875 7.16016 12.4434 5.66602 10.2188 5.66602C9.15625 5.66602 8.42578 6.26367 8.12695 6.82812H8.09375V5.86523H6.00195V12.9375H8.19336V9.45117C8.19336 8.52148 8.35938 7.625 9.52148 7.625C10.6504 7.625 10.6504 8.6875 10.6504 9.48438V12.9375H12.875Z"
                    fillRule="evenodd"
                />
            </svg>
        </a>
    );
};

IconLinkedin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconLinkedin.propTypes = { rootClassName: string, className: string };

export default IconLinkedin;
