import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Icon.module.css';

const IconYoutube = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <a href="https://www.youtube.com/@HouseSitMatch" target="_blank">
            <svg
                className={classes}
                width="19"
                height="13"
                viewBox="0 0 19 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.1035 2.27539C18.502 3.66992 18.502 6.6582 18.502 6.6582C18.502 6.6582 18.502 9.61328 18.1035 11.041C17.9043 11.8379 17.2734 12.4355 16.5098 12.6348C15.082 13 9.4375 13 9.4375 13C9.4375 13 3.75977 13 2.33203 12.6348C1.56836 12.4355 0.9375 11.8379 0.738281 11.041C0.339844 9.61328 0.339844 6.6582 0.339844 6.6582C0.339844 6.6582 0.339844 3.66992 0.738281 2.27539C0.9375 1.47852 1.56836 0.847656 2.33203 0.648438C3.75977 0.25 9.4375 0.25 9.4375 0.25C9.4375 0.25 15.082 0.25 16.5098 0.648438C17.2734 0.847656 17.9043 1.47852 18.1035 2.27539ZM7.57812 9.34766L12.293 6.6582L7.57812 3.96875V9.34766Z"
                    fillRule="evenodd"
                />
            </svg>
        </a>
    );
};

IconYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconYoutube.propTypes = { rootClassName: string, className: string };

export default IconYoutube;
