import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Icon.module.css';

const IconPinterest = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <a href="https://uk.pinterest.com/lamia1506/" target="_blank">
            <svg
                className={classes}
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17 8.625C17 13.1738 13.3145 16.8594 8.76562 16.8594C7.90234 16.8594 7.07227 16.7598 6.30859 16.4941C6.64062 15.9629 7.13867 15.0664 7.33789 14.3359C7.4375 13.9707 7.83594 12.377 7.83594 12.377C8.10156 12.9082 8.89844 13.3398 9.72852 13.3398C12.2188 13.3398 14.0117 11.0488 14.0117 8.22656C14.0117 5.50391 11.7871 3.44531 8.93164 3.44531C5.37891 3.44531 3.48633 5.83594 3.48633 8.42578C3.48633 9.6543 4.11719 11.1484 5.14648 11.6465C5.3125 11.7129 5.41211 11.6797 5.44531 11.5137C5.44531 11.4141 5.61133 10.8496 5.67773 10.584C5.67773 10.5176 5.67773 10.418 5.61133 10.3516C5.2793 9.95312 5.01367 9.18945 5.01367 8.45898C5.01367 6.66602 6.375 4.90625 8.73242 4.90625C10.7246 4.90625 12.1523 6.26758 12.1523 8.25977C12.1523 10.4844 11.0234 12.0117 9.5625 12.0117C8.76562 12.0117 8.16797 11.3477 8.33398 10.5508C8.56641 9.55469 9.03125 8.49219 9.03125 7.79492C9.03125 7.16406 8.69922 6.63281 8.00195 6.63281C7.17188 6.63281 6.50781 7.49609 6.50781 8.625C6.50781 9.35547 6.74023 9.85352 6.74023 9.85352C6.74023 9.85352 5.94336 13.3066 5.77734 13.9375C5.61133 14.668 5.67773 15.6641 5.74414 16.2949C2.68945 15.0996 0.53125 12.1445 0.53125 8.625C0.53125 4.07617 4.2168 0.390625 8.76562 0.390625C13.3145 0.390625 17 4.07617 17 8.625Z"
                    fillRule="evenodd"
                />
            </svg>
        </a>
    );
};

IconPinterest.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconPinterest.propTypes = { rootClassName: string, className: string };

export default IconPinterest;
